<template>
  <div v-if="contentNotFound">
   <Intersect v-if="loading" @enter="loadCategory" >
    <categoriesPlaceholder  />
   </Intersect>
    <categorySlider
      v-else
      :screen="screen"
      :contentList="sliderContents"
      :screenName="screenName"
      :playerInstance="playerInstance"
    ></categorySlider>
  </div>
</template>

<script>
import categoriesPlaceholder from "@/components/placeholders/categoriesPlaceholder.vue";
import categorySlider from "@/components/categorySlider/categorySlider.vue";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Intersect from 'vue-intersect'
export default {
  props: {
    screen: {
      type: Object,
    },
    id: {
      type: Number,
    },
    screenName: {
      type: String,
    },
    localLang: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contentList: false,
      showSlider: true,
      sliderContents: [],
      localDisplayLang: null,
      isMpegRequired: false,
      loading: true,
      contentNotFound: true,
      filterDeckingSubData: null,
    };
  },
  computed: {
    ...mapGetters(["getToken", "currentBrowser", "subscriberid", "subscriptionList", "appConfig"]),
  },
  watch: {
    getToken(val) {
      if (val) {
        this.loadCategory();
      }
    },
    subscriberid(val) {
      if (val) {
        this.loadCategory();
      }
    },
    screen(val) {
      this.loadCategory();
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mar' : this.localDisplayLang;
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mar' : this.localDisplayLang;
      this.loadCategory();
    });
  },
  mounted() {
    eventBus.$on(`${this.id}`, (response) => {
      if (!response.reason) {
        this.loading = false;
        this.contentList = true;
        this.sliderContents = [...response.data];
        if(this.appConfig.featureEnabled.isPromotionalBannerEnable){
          this.addScreensInPosition();
        }
        let dataLength = this.sliderContents.length;
        eventBus.$emit("on-data-callback", {"index": this.id, "is_data_available": dataLength > 0});
      } else if (response.errorcode === 7109 || response.errorcode === 8812) {
        this.contentList = true;
        this.showSlider = false;
        this.loading = true;
        this.contentNotFound = false;
        eventBus.$emit("on-data-callback", {"index": this.id, "is_data_available": false});

      }
    });
  },
  methods: {
    addScreensInPosition() {
      //add the subSontents Slides.
      
      this.filterDeckingSubData = null;
      this.appConfig.promotionalBanner.deckingSubContents.map((screenDeckingData, i) => {
        if(this.screenName.includes(screenDeckingData.screenId)){
          this.filterDeckingSubData = screenDeckingData.screenData;
        };
      });
      this.filterDeckingSubData && this.filterDeckingSubData.map((item, i) => {
        if(item.orderNo == this.id) {
          this.screen['subContents'] =  item.data;
        };
      });
      if (this.screen && this.screen.subContents && this.screen.subContents.length > 0) {
        this.screen.subContents.forEach((element) => {
          if (element.isEnabled && element.userTypes.includes("ALL")) {
            this.sliderContents.splice(element.position, 0, element);
          } else if (element.isEnabled && element.userTypes.includes(this.getUserType())) {
            this.sliderContents.splice(element.position, 0, element);
          }
        });
      }
    },
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptionList.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getEndPoint () {
      const sectionType = this.screen.sectionType;
      const itemType = this.screen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      }  else {
         return "/subscriber/v1/content"
      }
    },
    loadCategory() {
      if(!this.loading){
        return;
      }
      if (this.screen) {
        let payload = {
          endpoint: this.screen.endpoint || this.getEndPoint(),
          id: this.id,
          params: this.screen.parameters || this.screen.sectionData || {}
        };

        if (this.screen.sectionType == "ITEMLIST") {
          // let strItem = ''
           let item = this.screen.sectionData.map((data) => {
            // strItem += data.id + ","
            return data.id;
           })
          //  strItem = strItem.substring(0, strItem.length - 1);
           if (this.screen.itemType == "CONTENT") {
            payload.params = {
              contentlist : encodeURI(JSON.stringify((item))),
              orderby: {"objectid": item}
            }
           } else {
            payload.params = {
              contentlist : JSON.stringify(item)
            }
           }
         
        }
        let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }

        payload.params.page = 1;
        this.loading = true;
        this.contentList = false;
        this.showSlider = true;

        payload.params.displaylanguage = this.localDisplayLang;
        // alert(`Hello${payload.params.displaylanguage}`);
        if (this.isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }

        // if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        //   payload.params.genre = "Kids";
        // }

        eventBus.$emit("category-list", payload);
      }
    },
  },
  components: {
    Intersect,
    categoriesPlaceholder,
    categorySlider,
  },
};
</script>

<style></style>
