<template>
  <div>
    <section class="container">
      <div class="slider-header" :dir="localDisplayLang === 'mr' ? 'ltr' : 'ltr'">
        <div class="slider-title">
          <span class="title" :title="localDisplayLang === 'mr' ? (screen.title.mar || screen.title.ar) : screen.title.default">{{ localDisplayLang === "mr" ? (screen.title.mar || screen.title.ar) : screen.title.default }}</span>
        </div>
        <div class="slider-head">

          <div v-if="contentList.length >= appConfig.defaultpageSize || (screen.seeAllLink && contentList.length > 4)"
              class="seeall"
              @click="() => seeAll(screen)">
              
              <div> {{ $t("More") }} </div>
              <div> <img src="@/assets/icons/more-than-filled-50.png" alt="more" class="seeall-arrow" /> </div>
          </div> 

        </div>
      </div>


      <div class="slider-head-btns">
        <div :id="`controls-${sliderName}`" class="slider-controls slider-controls-header-set" :dir="localDisplayLang === 'mr' ? 'ltr' : ''" :style="[{bottom: screen.displayType == 'PORTRAIT' ? '-10rem' : '-5.5rem'}]"> 
            <button type="button">
              <img class="color-filler" src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
            <button type="button">
              <img class="color-filler" src="@/assets/icons/more-than-filled-50.png" width="30px" alt="more" />
            </button>
          </div>
      </div>


      <div :id="`slide-${sliderName}`" class="slider-content" :dir="localDisplayLang === 'mr' ? 'ltr' : 'ltr'">
        <!-- <div v-for="(content, index) in contentList" :key="index" class="content-slider" @click="clickHandler(content)"> -->
        <div v-for="(content, index) in contentList" :key="index" class="content-slider">
        <div style="margin:3px;" tabindex="1">
          
         <categoryCard
            :screen="screen"
            :content="content"
            :displayTags="true"
            :isDirectPlay="isPlayAbleContent(content)"
            :playerInstance="playerInstance"
            :showTitle="isTitleEnabled(content)"
            :cardType="fetchCardType(content)"
            :id="`${index}-${sliderName}`"
            @selection="(payload) => lightUp(payload)"
          />
        </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    screen: {
      type: Object,
    },
    contentList: {
      type: Array,
    },
    screenName: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
    listType: {
      type: String,
    },
  },
  data() {
    return {
      contents: [],
      detailContent: null,
      toggleDetail: null,
      toggleDetailPopup: null,
      differStyles: null,
      cardDisplayType: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["categorySelected", "appConfig", "getRtl", "subscriberid", "availabilityList", "subscriptionList"]),
    //creating slidername for carousel controls and container.
    sliderName() {
      return `${this.screen.title.default}`.replace(/[\s&/'/']+/g, "_");
    },
  },
  watch: {
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      // this.fetchCardType();
    });

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.contentList && this.contentList.length <= 7) {
        let dom = document.getElementById(`slide-${this.sliderName}-iw`);
        dom.style.marginLeft = "5px";
      }

      if (this.localDisplayLang === "ara" && this.contentList && this.contentList.length < 7) {
        document.getElementById(`slide-${this.sliderName}-iw`).style.textAlign = "-webkit-right";
        document.getElementById(`slide-${this.sliderName}-iw`).style.textAlign = "-moz-right";
      }
    }, 100);

    this.constructSlider();

    eventBus.$on("detail-close", () => {
      this.detailContent = null;
      this.toggleDetailCard(false);
    });

    eventBus.$on(`${this.sliderName}`, (content) => {
      this.detailContent = content;
      this.toggleDetailCard(true);
    });
  },
  methods: {
    isPlayAbleContent (content) {
      if (content.objecttype === 'CONTENT' && !content.seriesid  && !content.episodenum) {
        return false
      } else {
        if (content.tags && content.tags.toString().toLowerCase().includes('action-detail')) {
            return false;
          } else {
            return true
          }
      }
    },
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    isTitleEnabled(content) {
      if (content.hasOwnProperty("objecttype")) {
        return content.objecttype === "CONTENT" ? true : false;
      } else {
        return false;
      }
    },
    lightUp(payload) {
      if (screen.width > "900") {
        eventBus.$emit("off");
        eventBus.$emit(`highlight-${payload.id}`);
        this.detailContent = payload.content;
        this.toggleDetailCard(true);
      } else {
        let contentid = payload.content.objectid;
        this.$router.push({ name: `detail`, params: { contentid } });
      }
    },
    seeAll(screen) {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let screenData = JSON.stringify(screen);
      localStorage.setItem("screen-list-collection", screenData);

      if (screen.hasOwnProperty("seeAllLink") && screen.seeAllLink) {
        let section = this.spacialCharEncoding(screen.seeAllLink)
        this.$router.push({ name: "collections", params: { section: section.toLowerCase(), routeObject: screen, lang: currentLanguage } });
        // return ({ name: "collections", params: { section: section.toLowerCase(), routeObject: screen, lang: currentLanguage } });
      } else {
          let titleDefault = this.spacialCharEncoding(screen.title.default);
          this.$router.push({
          name: "collections",
          params: { section: titleDefault.toLowerCase(), routeObject: screen, lang: currentLanguage },
        });
        // return ({
        //   name: "collections",
        //   params: { section: titleDefault.toLowerCase(), routeObject: screen, lang: currentLanguage },
        // });
      }
    },
    toggleDetailCard(state) {
      this.toggleDetail = state;
    },
    closeDetailPopup(state) {
      this.toggleDetailPopup = state;
    },
    constructSlider() {
      let data = {
        container: `#slide-${this.sliderName}`,
        controlsContainer: `#controls-${this.sliderName}`,
        nav: false,
        loop: false,
        slideBy: "page",
        gutter: 5,
        fixedWidth: 215,
      };
      if (this.screen.displayType === "PORTRAIT") {
        data.fixedWidth = 215;
      } else {
        data.fixedWidth = 280;
      }
      if (window.screen.width <= 600) {
        if (this.screen.displayType === "PORTRAIT") {
        data.fixedWidth = 120;
      } else {
        data.fixedWidth = 180;
      }
      }

      if (this.screen.displayType === "SQUARE" || this.screen.displayType === "PORTRAIT") {
        // data.responsive = {
  
        //   320: {
        //     items: 3,
        //     edgePadding: 5,
        //     gutter: 10,
        //   },
        //   400: {
        //     items: 3,
        //     edgePadding: 5,
        //     gutter: 7,
        //   },
        //   576: {
        //     items: 3,
        //     edgePadding: 5,
        //     gutter: 5,
        //   },
        //   768: {
        //     items: 4,
        //     edgePadding: 5,
        //     gutter: 5,
        //   },
        //   900: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 4.3,
        //   },
        //   1024: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 5,
        //   },
        //   1200: {
        //     edgePadding: 5,
        //     gutter: 10,
        //     items: 5.6,
        //   },

        //   1280: {
        //     edgePadding: 5,
        //     gutter: 10,
        //     items: 6.3,
        //   },
        //   1290: {
        //     edgePadding: 5,
        //     gutter: 10,
        //     items: 5.6,
        //   },

        //   1366: {
        //     edgePadding: 5,
        //     gutter: 10,
        //     items: 6.3,
        //   },
        //   1440: {
        //     edgePadding: 5,
        //     gutter: 10,
        //     items: 6.3,
        //   },
        //   1600: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 7.3,
        //   },
        //   1680: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 7.3,
        //   },
        //   1920: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 8.3,
        //   },
        //   2200: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 9.3,
        //   },
        //   2400: {
        //     edgePadding: 5,
        //     gutter: 15,
        //     items: 10.3,
        //   },
        // };
      } else if (this.screen.displayType === "LANDSCAPE") {
        data.responsive = {
          320: {
            items: 3,
            edgePadding: 5,
            gutter: 7,
          },
          576: {
            items: 3,
            edgePadding: 5,
            gutter: 7,
          },
          600: {
            edgePadding: 5,
            gutter: 15,
            items: 3,
          },
          768: {
            edgePadding: 5,
            gutter: 15,
            items: 3,
          },
          900: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1024: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1200: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1280: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1366: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1440: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },
          1600: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },
          1680: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          1920: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          2200: {
            edgePadding: 5,
            gutter: 15,
            items: 7,
          },
          2400: {
            edgePadding: 5,
            gutter: 15,
            items: 8,
          },
        };
      }
      this.$nextTick(() => {
        this.slider = tns(data);

        if (this.localDisplayLang === "ara") {
          this.slider.goTo("last");
        } else {
          this.slider.goTo("first");
        }
      });
    },
    fetchCardType(content) {
      if (screen.width <= 375) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              playButton: {
                enablePlay: this.isPlayAbleContent(content),
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
              width: "160px",
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "150px",
              width: "105px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 375 && screen.width <= 425) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              width: "170px",
              playButton: {
                enablePlay: this.isPlayAbleContent(content),
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "170px",
              width: "110px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 425 && screen.width <= 800) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "147px",
              width: "240px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "260px",
              width: "180px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            };
      } else if (screen.width > 800 && screen.width < 1050) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "120px",
              width: "210px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "220px",
              width: "150px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            };
      } else if (screen.width > 1200 && screen.width < 1350) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "140px",
              width: "270px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "240px",
              width: "157px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: this.isPlayAbleContent(content) },
            };
      } else {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "150px",
              width: "260px",
              playButton: {
                enablePlay: this.isPlayAbleContent(content),
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "300px",
              width: "200px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      }
    },
    clickHandler(content) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (content.objecttype !== "CONTENT" || content.category === "MOVIE") {
        if (screen.width > 0) {
          //this.openDetailPopup(content);
          if (content.category == "TVSHOW") {
           
             let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPage",
              params: { contentId: content.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage },
            });
          } else {
            
             let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPageMovie",
              params: { mediaTitle: title.toLowerCase(), contentId: content.objectid.toLowerCase(), lang: currentLanguage },
            });
          }
        } else {
          //this.$router.push({ name: "detail", params: { contentid: content.objectid } });
        }
      }
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categorycard" */ "@/components/Templates/categoryCard.vue"),
  },
  beforeDestroy() {
    if (this.slider) {
      this.slider.destroy();
    }
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "./categorySlider.scss"
</style>